import { useNavigate } from "react-router-dom";

import { FiInstagram } from "react-icons/fi";
import { GrYoutube } from "react-icons/gr";
import { HiArrowLongRight } from "react-icons/hi2";
import { ImFacebook } from "react-icons/im";

export default function FooterShopifyVelomad() {
    const navigate = useNavigate();
    const handleClickNewsletter = () => {
        navigate("/newsletter");
    };

    return (
        <footer className="footer-maps">
            <p className="footer-title">We are mad about bikes</p>
            <div className="footer-content">
                <div className="footer-content-network">
                    <div
                        className="footer-content-network-newsletter"
                        onClick={() => handleClickNewsletter()}
                    >
                        <label htmlFor="newsletter">Join our community</label>
                        <input
                            type="text"
                            id="newsletter"
                            name="newsletter"
                            placeholder="Entrez votre E-mail"
                        />
                        <span>
                            <HiArrowLongRight />
                        </span>
                    </div>
                    <div className="footer-social">
                        <div className="logo">
                            <a
                                href="https://www.youtube.com/@levelomad152"
                                rel="noreferrer"
                            >
                                <span className="text-white">
                                    <GrYoutube />
                                </span>
                            </a>
                            <a
                                href="https://www.facebook.com/levelomad/?locale=fr_FR"
                                rel="noreferrer"
                            >
                                <span>
                                    <ImFacebook />
                                </span>
                            </a>
                            <a
                                href="https://www.instagram.com/levelomad/?hl=fr"
                                rel="noreferrer"
                            >
                                <span className="text-white">
                                    <FiInstagram />
                                </span>
                            </a>
                        </div>
                        <div className="mentions">
                            <a
                                href="https://levelomad.com/pages/mentions-legales"
                                rel="noreferrer"
                            >
                                Mentions légales
                            </a>{" "}
                            —{" "}
                            <a
                                href="https://levelomad.com/pages/conditions-utilisation"
                                rel="noreferrer"
                            >
                                Conditions de vente
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-content-listing">
                    <div className="list-link">
                        <h2>A propos</h2>
                        <a
                            href="https://levelomad.com/pages/nos-magasins"
                            rel="noreferrer"
                        >
                            Nos Magasins
                        </a>
                        <a
                            href="https://levelomad.com/pages/concept"
                            rel="noreferrer"
                        >
                            Le Concept
                        </a>
                        <a
                            href="https://levelomad.com/pages/le-vae-officiel-du-tour-de-france"
                            rel="noreferrer"
                        >
                            Le VAE Officiel
                        </a>
                        <a
                            href="https://levelomad.com/blogs/le-blog-velomad"
                            rel="noreferrer"
                        >
                            Le Blog
                        </a>
                        <a
                            href="https://levelomad.com/pages/certificat-d-homologation"
                            rel="noreferrer"
                        >
                            Certificats d'homologation
                        </a>
                        <a
                            href="https://levelomad.com/pages/reconditionnement-batteries"
                            rel="noreferrer"
                        >
                            Reconditionnement de batteries
                        </a>
                    </div>
                    <div className="list-link">
                        <h2>Nos services</h2>
                        <a
                            href="https://levelomad.com/pages/parrainage-velomad"
                            rel="noreferrer"
                        >
                            Parrainage Velomad
                        </a>
                        <a
                            href="https://levelomad.com/pages/livraison"
                            rel="noreferrer"
                        >
                            Livraison{" "}
                        </a>

                        <a
                            href="https://levelomad.com/pages/guide-des-tailles"
                            rel="noreferrer"
                        >
                            Guide des tailles
                        </a>
                        <a
                            href="https://levelomad.com/blogs/le-blog-velomad/comment-choisir-son-velo-de-route-electrique"
                            rel="noreferrer"
                        >
                            Comment choisir son vélo ?
                        </a>
                        <a
                            href="https://levelomad.com/pages/facilites-de-paiement"
                            rel="noreferrer"
                        >
                            Facilités de paiement
                        </a>
                        <a
                            href="https://levelomad.com/pages/retours"
                            rel="noreferrer"
                        >
                            Retours
                        </a>
                    </div>
                    <div className="list-link">
                        <h2>Aide</h2>
                        <a
                            href="https://levelomad.com/pages/faq"
                            rel="noreferrer"
                        >
                            FAQ
                        </a>
                        <a
                            href="https://levelomad.com/pages/service-apres-vente"
                            rel="noreferrer"
                        >
                            Service Après Vente
                        </a>
                        <a
                            href="https://levelomad.com/pages/nos-tutoriels"
                            rel="noreferrer"
                        >
                            Nos Tutoriels
                        </a>
                        <a
                            href="https://levelomad.com/pages/contact"
                            rel="noreferrer"
                        >
                            Contact
                        </a>
                        <a
                            href="https://levelomad.com/pages/rejoindre-l-equipe-mad"
                            rel="noreferrer"
                        >
                            Rejoindre l'équipe
                        </a>
                        <a
                            href="https://levelomad.com/pages/subventions-velo"
                            rel="noreferrer"
                        >
                            Subventions à l'achat
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-social-mobile">
                <div className="logo">
                    <a
                        href="https://www.youtube.com/@levelomad152"
                        rel="noreferrer"
                    >
                        <span className="text-white">
                            <GrYoutube />
                        </span>
                    </a>
                    <a
                        href="https://www.facebook.com/levelomad/?locale=fr_FR"
                        rel="noreferrer"
                    >
                        <span>
                            <ImFacebook />
                        </span>
                    </a>
                    <a
                        href="https://www.instagram.com/levelomad/?hl=fr"
                        rel="noreferrer"
                    >
                        <span className="text-white">
                            <FiInstagram />
                        </span>
                    </a>
                </div>
                <div className="mentions">
                    Mentions légales - Conditions de vente
                </div>
            </div>
            <div className="footer-copyright">
                <div className="footer-svg">
                    <svg
                        width="100%"
                        viewBox="0 0 1354 200"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mm-footer-logo-svg"
                    >
                        <path
                            d="M113.599 121.853L76.7769 5.16504H0L69.6675 194.185H157.099L223.653 20.6725V194.185H365.67V140.801H294.652V123.582H365.67V77.078H294.652V58.5492H365.67V5.16504H149.99L113.599 121.853Z"
                            fill="white"
                        ></path>
                        <path
                            d="M1245.24 5.16915H1164.04V172.238L1106.35 5.16915H1006.05L949.706 174.386V5.16915H857.397L826.784 94.3005L795.72 5.16915H700.748V69.11C687.561 29.0309 648.713 0 596.903 0C532.563 0 488.632 44.775 488.632 99.8883C488.632 112.629 491.014 124.824 495.497 136.054H447.814V5.16915H377.246V194.189H506.377V156.021C524.966 182.14 557.005 199.34 596.903 199.34C648.713 199.34 687.561 170.309 700.748 130.448V194.171H768.652V104.639L800.166 194.207H850.757L881.37 104.639V194.207H1017.16L1023.5 173.53H1088.57L1094.84 194.207H1245.28C1311.85 194.207 1354 154.601 1354 99.9065C1354 45.2118 1311.85 5.16915 1245.28 5.16915H1245.24ZM596.903 136.491C575.163 136.491 559.631 120.128 559.631 99.8883C559.631 79.6485 575.163 62.8488 596.903 62.8488C618.644 62.8488 634.626 79.2117 634.626 99.8883C634.626 120.565 619.094 136.491 596.903 136.491ZM1038 126.153L1056.21 66.7257L1074.22 126.153H1038.02H1038ZM1245.26 136.491H1235.05V62.867H1245.71C1267.9 62.867 1282.98 79.2299 1282.98 99.9065C1282.98 120.583 1267.45 136.509 1245.26 136.509V136.491Z"
                            fill="white"
                        ></path>
                    </svg>
                </div>

                <div>
                    <span>©Velomad </span>Website by{" "}
                    <a
                        href="https://bigcheese.fr/"
                        className="text-white  mm-p-middle-bold"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <span> BigChesse</span>
                    </a>{" "}
                    &{" "}
                    <a
                        href="https://litiboost.fr/"
                        className="text-white  mm-p-middle-bold"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <span> Litiboost</span>
                    </a>
                </div>
            </div>
        </footer>
    );
}
