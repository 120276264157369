/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import "./maps.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Modal from "../Components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../Redux/actions/style.action";
import {
    getDataMap,
    setFilterCat,
    showElementModal,
} from "../Redux/actions/map.actions";

import iconShowOnMap from "../Assets/icon-showOnMap.png";
import "../Components/Header/header.css";
import mascotte from "../Assets/mascotte_velomad.png";

import iconAmbassadeurs from "../Assets/icon-ambassadeurs-mascotte.png";
import iconAmbassadeursOver from "../Assets/icon-ambassadeurs-cerclage-jaune-mascotte.png";

import iconReparateurs from "../Assets/icon-reparateurs.png";
import iconRevendeurs from "../Assets/icon-revendeurs.png";
import iconShowrooms from "../Assets/icon-showrooms.png";
import FooterShopifyVelomad from "../Components/shopifyVelomad/footer";
import SingeFooterShopifyVelomad from "../Components/shopifyVelomad/singeFooter";
import HeaderShopifyVelomad from "../Components/shopifyVelomad/headerShopify";
import { MdExpandMore } from "react-icons/md";

export default function Maps() {
    document.title = "Découvrez les partenaires Velomad";
    document.description =
        "Velomad s’associe avec des partenaires, réparateurs et ambassadeur dans toute la France pour être le plus disponible pour vous.";

    function ExternalStateExample() {
        const [map, setMap] = useState(null);
        const dispatch = useDispatch();
        const handleClick = (element) => {
            dispatch(showElementModal(element));
            dispatch(openModal());
        };

        const [showOnMap, setShowOnMap] = useState(undefined);
        const elements = useSelector((state) => state.Map.elements);
        const departements = useSelector((state) => state.Map.departments);
        const categories = useSelector((state) => state.Map.categories);
        const showModal = useSelector((state) => state.style.showModal);
        const filterCat = useSelector((state) => state.Map.filterCat);

        const [activDevelop, setActiveDevelop] = useState(false);
        const [developFr, setDevelopFr] = useState(false);
        const [developBe, setDevelopBe] = useState(false);
        const [developSui, setDevelopSui] = useState(false);
        const [developMonaco, setDevelopMonaco] = useState(false);
        useEffect(() => {
            dispatch(getDataMap());

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        useEffect(() => {
            if (showModal === false && map !== null) {
                map.setView(
                    [46.7775, 2.4281],
                    window.innerWidth <= 750 ? 5 : 6
                );
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showModal]);

        useEffect(() => {
            if (activDevelop === true) {
                setDevelopFr(false);
                setDevelopBe(false);
                setDevelopSui(false);
                setDevelopMonaco(false);
            } else {
                setDevelopFr(true);
                setDevelopBe(true);
                setDevelopSui(true);
                setDevelopMonaco(true);
            }
        }, [activDevelop]);

        const displayMap = useMemo(
            () => (
                <MapContainer
                    center={[46.7775, 2.4281]}
                    zoom={window.innerWidth <= 750 ? 5 : 6}
                    scrollWheelZoom={false}
                    ref={setMap}
                    className="centered "
                    markerZoomAnimation={true}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        className="grey-maps"
                    />
                    <>
                        {elements !== null &&
                            elements !== undefined &&
                            elements.map((element) => (
                                <>
                                    {(element.category.includes(
                                        filterCat.id
                                    ) === true ||
                                        filterCat.name === "") &&
                                        element.visible === true && (
                                            <>
                                                {showOnMap === element ? (
                                                    <Marker
                                                        key={element.id}
                                                        position={
                                                            element.gps.split(
                                                                ","
                                                            )[0].length >= 3 &&
                                                            element.gps.split(
                                                                ","
                                                            )[1].length >= 3
                                                                ? [
                                                                      parseFloat(
                                                                          element.gps.split(
                                                                              ","
                                                                          )[0]
                                                                      ),
                                                                      parseFloat(
                                                                          element.gps.split(
                                                                              ","
                                                                          )[1]
                                                                      ),
                                                                  ]
                                                                : [
                                                                      46.7775,
                                                                      2.4281,
                                                                  ]
                                                        }
                                                        zIndexOffset={
                                                            showOnMap ===
                                                            element
                                                                ? 10
                                                                : 1
                                                        }
                                                        icon={
                                                            element.category !==
                                                                null &&
                                                            element.category !==
                                                                undefined &&
                                                            element.category.includes(
                                                                "2"
                                                            )
                                                                ? new L.Icon({
                                                                      iconUrl:
                                                                          iconAmbassadeursOver,
                                                                      iconRetinaUrl:
                                                                          iconAmbassadeursOver,
                                                                      popupAnchor:
                                                                          [
                                                                              15,
                                                                              15,
                                                                          ],
                                                                      iconSize:
                                                                          [
                                                                              30,
                                                                              30,
                                                                          ],
                                                                  })
                                                                : new L.Icon({
                                                                      iconUrl:
                                                                          iconShowOnMap,
                                                                      iconRetinaUrl:
                                                                          iconShowOnMap,
                                                                      popupAnchor:
                                                                          [
                                                                              15,
                                                                              15,
                                                                          ],
                                                                      iconSize:
                                                                          [
                                                                              30,
                                                                              30,
                                                                          ],
                                                                  })
                                                        }
                                                        eventHandlers={{
                                                            click: () => {
                                                                map.setView(
                                                                    [
                                                                        parseFloat(
                                                                            element.gps.split(
                                                                                ","
                                                                            )[0]
                                                                        ),
                                                                        parseFloat(
                                                                            element.gps.split(
                                                                                ","
                                                                            )[1]
                                                                        ),
                                                                    ],
                                                                    13
                                                                );
                                                                handleClick(
                                                                    element
                                                                );
                                                            },
                                                        }}
                                                    >
                                                        <Tooltip className="text-center">
                                                            {element.category !==
                                                                undefined &&
                                                                element.category !==
                                                                    null &&
                                                                element.category
                                                                    .split(",")
                                                                    .map(
                                                                        (
                                                                            elemCat
                                                                        ) => (
                                                                            <>
                                                                                {categories.map(
                                                                                    (
                                                                                        cat
                                                                                    ) => (
                                                                                        <>
                                                                                            {parseFloat(
                                                                                                cat.id
                                                                                            ) ===
                                                                                                parseFloat(
                                                                                                    elemCat
                                                                                                ) && (
                                                                                                <>
                                                                                                    <span>
                                                                                                        {
                                                                                                            cat.name
                                                                                                        }

                                                                                                        s
                                                                                                    </span>
                                                                                                    <br />
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                            <hr />
                                                            {element.title}{" "}
                                                            <hr /> à{" "}
                                                            {element.city}
                                                        </Tooltip>
                                                    </Marker>
                                                ) : (
                                                    <Marker
                                                        key={element.id}
                                                        position={
                                                            element.gps.split(
                                                                ","
                                                            )[0].length >= 3 &&
                                                            element.gps.split(
                                                                ","
                                                            )[1].length >= 3
                                                                ? [
                                                                      parseFloat(
                                                                          element.gps.split(
                                                                              ","
                                                                          )[0]
                                                                      ),
                                                                      parseFloat(
                                                                          element.gps.split(
                                                                              ","
                                                                          )[1]
                                                                      ),
                                                                  ]
                                                                : [
                                                                      46.7775,
                                                                      2.4281,
                                                                  ]
                                                        }
                                                        zIndexOffset={
                                                            showOnMap ===
                                                            element
                                                                ? 10
                                                                : 1
                                                        }
                                                        icon={
                                                            new L.Icon({
                                                                iconUrl:
                                                                    element.url_icon_map,
                                                                iconRetinaUrl:
                                                                    element.url_icon_map,
                                                                popupAnchor: [
                                                                    15, 15,
                                                                ],
                                                                iconSize: [
                                                                    30, 30,
                                                                ],
                                                            })
                                                        }
                                                        eventHandlers={{
                                                            click: () => {
                                                                map.setView(
                                                                    [
                                                                        parseFloat(
                                                                            element.gps.split(
                                                                                ","
                                                                            )[0]
                                                                        ),
                                                                        parseFloat(
                                                                            element.gps.split(
                                                                                ","
                                                                            )[1]
                                                                        ),
                                                                    ],
                                                                    13
                                                                );
                                                                handleClick(
                                                                    element
                                                                );
                                                            },
                                                        }}
                                                    >
                                                        <Tooltip className="text-center">
                                                            {element.category !==
                                                                undefined &&
                                                                element.category !==
                                                                    null &&
                                                                element.category
                                                                    .split(",")
                                                                    .map(
                                                                        (
                                                                            elemCat
                                                                        ) => (
                                                                            <>
                                                                                {categories.map(
                                                                                    (
                                                                                        cat
                                                                                    ) => (
                                                                                        <>
                                                                                            {parseFloat(
                                                                                                cat.id
                                                                                            ) ===
                                                                                                parseFloat(
                                                                                                    elemCat
                                                                                                ) && (
                                                                                                <>
                                                                                                    <span>
                                                                                                        {
                                                                                                            cat.name
                                                                                                        }

                                                                                                        s
                                                                                                    </span>
                                                                                                    <br />
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                            <hr />
                                                            {element.title}{" "}
                                                            <hr /> à{" "}
                                                            {element.city}
                                                        </Tooltip>
                                                    </Marker>
                                                )}
                                            </>
                                        )}
                                </>
                            ))}
                    </>
                </MapContainer>
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [elements, showModal, filterCat, showOnMap]
        );
        const openNavBurger = useSelector((state) => state.style.openNavBurger);

        return (
            <>
                <HeaderShopifyVelomad />
                {openNavBurger === false && (
                    <>
                        <main className="main-maps">
                            <div className="banner-map"></div>
                            <div className="banner-title">
                                <h1>Nos Partenaires</h1>
                            </div>
                            <section className="found-map">
                                <div className="found-map-title m-auto text-center">
                                    <img
                                        src={mascotte}
                                        alt="Mascotte VeloMad"
                                        className="mascotte-velomad"
                                    />
                                    <h2>
                                        Trouvez un ambassadeur ou réparateur
                                        près de chez vous
                                    </h2>
                                </div>
                                <div className="found-map-content">
                                    <div className="found-map-content-card">
                                        <div className="found-map-content-card-title">
                                            <h3>Nos Magasins </h3>
                                        </div>
                                        <div className="found-map-content-card-text">
                                            <p>
                                                Venez visitez nos Magasins de
                                                Rouen et de Paris afin de
                                                découvrir toute la gamme et de
                                                retrouver tout l’esprit de la
                                                marque Velomad.
                                            </p>
                                            <p>
                                                Vous pouvez aussi tester nos
                                                vélos et également faire les
                                                entretiens et réparations
                                                nécessaires.
                                            </p>
                                        </div>{" "}
                                        <img
                                            src={iconShowrooms}
                                            alt={"icone Magasins"}
                                            className=" found-map-content-card-img"
                                        />
                                    </div>
                                    <div className="found-map-content-card">
                                        <div className="found-map-content-card-title">
                                            <h3>Nos Ambassadeurs </h3>
                                        </div>
                                        <div className="found-map-content-card-text">
                                            <p>
                                                Nos ambassadeurs sont là pour
                                                vous permettre d’essayer nos
                                                vélos et de vous renseigner.
                                            </p>
                                            <p>
                                                Trouver celui qui est proche de
                                                chez vous et prenez rendez-vous
                                                pour un test !
                                            </p>
                                        </div>{" "}
                                        <img
                                            src={iconAmbassadeurs}
                                            alt={"icone Ambassadeurs"}
                                            className="found-map-content-card-img"
                                        />
                                    </div>

                                    <div className="found-map-content-card">
                                        <div className="found-map-content-card-title">
                                            <h3>Nos Revendeurs</h3>
                                        </div>
                                        <div className="found-map-content-card-text">
                                            <p>
                                                Bénéficiez d’un réseau de
                                                réparateurs partenaires partout
                                                en France pour faire les
                                                entretiens & réparations de vos
                                                Velomad.
                                            </p>
                                            <p>
                                                Si vous n’en trouvez pas à côté
                                                de chez vous, n’hésitez pas à
                                                nous contacter
                                            </p>
                                        </div>
                                        <img
                                            src={iconRevendeurs}
                                            alt={"icone Revendeurs"}
                                            className=" found-map-content-card-img"
                                        />
                                    </div>
                                    <div className="found-map-content-card">
                                        <div className="found-map-content-card-title">
                                            <h3>Nos Reparateurs </h3>
                                        </div>
                                        <div className="found-map-content-card-text">
                                            <p>
                                                Vous n’habitez pas à proximité
                                                d’un de nos 2 Magasins ?
                                            </p>
                                            <p>
                                                Trouvez un revendeur proche de
                                                chez vous afin d’aller voir nos
                                                vélos et obtenir des
                                                renseignements sur nos
                                                différents modèles.
                                            </p>
                                        </div>{" "}
                                        <img
                                            src={iconReparateurs}
                                            alt={"icone Réparateurs"}
                                            className="found-map-content-card-img"
                                        />
                                    </div>
                                </div>
                            </section>
                            <section className="bg-light">
                                <div className="filter-categories">
                                    <h2>Découvrez nos partenaires</h2>
                                    <h3>FILTREZ VOTRE RECHERCHE</h3>
                                </div>
                                <div className="filter-search">
                                    {" "}
                                    <div
                                        key={"cat" + 0}
                                        className={
                                            filterCat.id === 0
                                                ? "filterCatSelected "
                                                : "filterCat "
                                        }
                                        onClick={() => {
                                            dispatch(
                                                setFilterCat({
                                                    id: 0,
                                                    name: "",
                                                })
                                            );

                                            setActiveDevelop(true);
                                        }}
                                    >
                                        Tout
                                    </div>
                                    {categories !== null &&
                                        categories !== undefined &&
                                        categories.length > 0 &&
                                        categories.map((categorie) => (
                                            <div
                                                key={"cat" + categorie.id}
                                                className={
                                                    filterCat.id ===
                                                    categorie.id
                                                        ? "filterCatSelected"
                                                        : "filterCat"
                                                }
                                                onClick={() => {
                                                    dispatch(
                                                        setFilterCat(categorie)
                                                    );
                                                    dispatch(closeModal());
                                                    setActiveDevelop(false);
                                                }}
                                            >
                                                <div>{categorie.name}s</div>
                                                <div>
                                                    {" "}
                                                    {parseInt(categorie.id) ===
                                                        parseInt(1) && (
                                                        <img
                                                            src={iconShowrooms}
                                                            alt={
                                                                "icone + " +
                                                                categorie.name
                                                            }
                                                            className="icon-cat"
                                                        />
                                                    )}
                                                    {parseInt(categorie.id) ===
                                                        parseInt(2) && (
                                                        <img
                                                            src={
                                                                iconAmbassadeurs
                                                            }
                                                            alt={
                                                                "icone + " +
                                                                categorie.name
                                                            }
                                                            className="icon-cat"
                                                        />
                                                    )}
                                                    {parseInt(categorie.id) ===
                                                        parseInt(3) && (
                                                        <img
                                                            src={iconRevendeurs}
                                                            alt={
                                                                "icone + " +
                                                                categorie.name
                                                            }
                                                            className="icon-cat"
                                                        />
                                                    )}
                                                    {parseInt(categorie.id) ===
                                                        parseInt(4) && (
                                                        <img
                                                            src={
                                                                iconReparateurs
                                                            }
                                                            alt={
                                                                "icone + " +
                                                                categorie.name
                                                            }
                                                            className="icon-cat"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                </div>{" "}
                            </section>
                            <div className={"map-modal"}>
                                {displayMap}
                                <div className="search-with-modal">
                                    <div className="modal_map ">
                                        {filterCat !== null && (
                                            <>
                                                <h4 className="small-country">
                                                    {filterCat.name} En France :{" "}
                                                    {activDevelop === true && (
                                                        <span
                                                            className={
                                                                developFr ===
                                                                true
                                                                    ? "developIcon"
                                                                    : "developIcon-reverse"
                                                            }
                                                            onClick={() =>
                                                                setDevelopFr(
                                                                    !developFr
                                                                )
                                                            }
                                                        >
                                                            {developFr === true
                                                                ? "Fermer"
                                                                : "Développer"}{" "}
                                                            <MdExpandMore />
                                                        </span>
                                                    )}
                                                </h4>

                                                <div
                                                    className={
                                                        developFr === false
                                                            ? "modal-result-search-hidden"
                                                            : "modal-result-search"
                                                    }
                                                >
                                                    {elements !== null &&
                                                        elements !==
                                                            undefined &&
                                                        elements.length > 0 &&
                                                        elements.map(
                                                            (element) => (
                                                                <>
                                                                    {(element.country ===
                                                                        "France" ||
                                                                        element.country ===
                                                                            "france") &&
                                                                        element.visible ===
                                                                            true &&
                                                                        (element.category.includes(
                                                                            filterCat.id
                                                                        ) ===
                                                                            true ||
                                                                            filterCat.name ===
                                                                                "") && (
                                                                            <>
                                                                                {departements !==
                                                                                    undefined &&
                                                                                    departements.map(
                                                                                        (
                                                                                            departement
                                                                                        ) => (
                                                                                            <>
                                                                                                {element.zipcode !==
                                                                                                    "" &&
                                                                                                    element.zipcode
                                                                                                        .slice(
                                                                                                            0,
                                                                                                            2
                                                                                                        )
                                                                                                        .includes(
                                                                                                            departement.num_dep
                                                                                                        ) && (
                                                                                                        <div
                                                                                                            className="pointer"
                                                                                                            onClick={() => {
                                                                                                                map.setView(
                                                                                                                    [
                                                                                                                        parseFloat(
                                                                                                                            element.gps.split(
                                                                                                                                ","
                                                                                                                            )[0]
                                                                                                                        ),
                                                                                                                        parseFloat(
                                                                                                                            element.gps.split(
                                                                                                                                ","
                                                                                                                            )[1]
                                                                                                                        ),
                                                                                                                    ],
                                                                                                                    13
                                                                                                                );
                                                                                                                handleClick(
                                                                                                                    element
                                                                                                                );
                                                                                                                dispatch(
                                                                                                                    openModal()
                                                                                                                );
                                                                                                            }}
                                                                                                            onMouseOver={() =>
                                                                                                                setShowOnMap(
                                                                                                                    element
                                                                                                                )
                                                                                                            }
                                                                                                            onMouseLeave={() =>
                                                                                                                setShowOnMap(
                                                                                                                    undefined
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <h3>
                                                                                                                {
                                                                                                                    element.title
                                                                                                                }
                                                                                                            </h3>

                                                                                                            {element.url_img_header !==
                                                                                                                "none" && (
                                                                                                                <img
                                                                                                                    className="img-map-gestion mx-auto"
                                                                                                                    src={
                                                                                                                        element.url_img_header
                                                                                                                    }
                                                                                                                    alt={
                                                                                                                        element.title
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                            <small>
                                                                                                                {
                                                                                                                    departement.num_dep
                                                                                                                }{" "}
                                                                                                                -{" "}
                                                                                                                {
                                                                                                                    departement.dep_name
                                                                                                                }
                                                                                                            </small>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </>
                                                                                        )
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                </>
                                                            )
                                                        )}
                                                </div>
                                                {elements !== null &&
                                                    elements !== undefined &&
                                                    elements.length > 0 &&
                                                    elements.filter(
                                                        (element) =>
                                                            (element.country ===
                                                                "Belgique" ||
                                                                element.country ===
                                                                    "belgique") &&
                                                            element.visible ===
                                                                true &&
                                                            (element.category.includes(
                                                                filterCat.id
                                                            ) === true ||
                                                                filterCat.name ===
                                                                    "")
                                                    ).length >= 1 && (
                                                        <h4 className="small-country">
                                                            {filterCat.name} En
                                                            Belgique :{" "}
                                                            {activDevelop ===
                                                                true && (
                                                                <span
                                                                    className={
                                                                        developBe ===
                                                                        true
                                                                            ? "developIcon"
                                                                            : "developIcon-reverse"
                                                                    }
                                                                    onClick={() =>
                                                                        setDevelopBe(
                                                                            !developBe
                                                                        )
                                                                    }
                                                                >
                                                                    {developBe ===
                                                                    true
                                                                        ? "Fermer"
                                                                        : "Développer"}{" "}
                                                                    <MdExpandMore />
                                                                </span>
                                                            )}
                                                        </h4>
                                                    )}
                                                <div
                                                    className={
                                                        developBe === false
                                                            ? "modal-result-search-hidden"
                                                            : "modal-result-search"
                                                    }
                                                >
                                                    {elements !== null &&
                                                        elements !==
                                                            undefined &&
                                                        elements.length > 0 &&
                                                        elements.map(
                                                            (element) => (
                                                                <>
                                                                    {(element.country ===
                                                                        "Belgique" ||
                                                                        element.country ===
                                                                            "belgique") &&
                                                                        element.visible ===
                                                                            true &&
                                                                        (element.category.includes(
                                                                            filterCat.id
                                                                        ) ===
                                                                            true ||
                                                                            filterCat.name ===
                                                                                "") && (
                                                                            <>
                                                                                {departements !==
                                                                                    undefined &&
                                                                                    departements.map(
                                                                                        (
                                                                                            departement
                                                                                        ) => (
                                                                                            <>
                                                                                                {element.zipcode !==
                                                                                                    "" &&
                                                                                                    element.zipcode
                                                                                                        .slice(
                                                                                                            0,
                                                                                                            2
                                                                                                        )
                                                                                                        .includes(
                                                                                                            departement.num_dep
                                                                                                        ) && (
                                                                                                        <div
                                                                                                            className="pointer"
                                                                                                            onClick={() => {
                                                                                                                map.setView(
                                                                                                                    [
                                                                                                                        parseFloat(
                                                                                                                            element.gps.split(
                                                                                                                                ","
                                                                                                                            )[0]
                                                                                                                        ),
                                                                                                                        parseFloat(
                                                                                                                            element.gps.split(
                                                                                                                                ","
                                                                                                                            )[1]
                                                                                                                        ),
                                                                                                                    ],
                                                                                                                    13
                                                                                                                );
                                                                                                                handleClick(
                                                                                                                    element
                                                                                                                );
                                                                                                                dispatch(
                                                                                                                    openModal()
                                                                                                                );
                                                                                                            }}
                                                                                                            onMouseOver={() =>
                                                                                                                setShowOnMap(
                                                                                                                    element
                                                                                                                )
                                                                                                            }
                                                                                                            onMouseLeave={() =>
                                                                                                                setShowOnMap(
                                                                                                                    undefined
                                                                                                                )
                                                                                                            }
                                                                                                        >
                                                                                                            <h3>
                                                                                                                {
                                                                                                                    element.title
                                                                                                                }
                                                                                                            </h3>

                                                                                                            {element.url_img_header !==
                                                                                                                "none" && (
                                                                                                                <img
                                                                                                                    className="img-map-gestion mx-auto"
                                                                                                                    src={
                                                                                                                        element.url_img_header
                                                                                                                    }
                                                                                                                    alt={
                                                                                                                        element.title
                                                                                                                    }
                                                                                                                />
                                                                                                            )}
                                                                                                            <small>
                                                                                                                {
                                                                                                                    departement.num_dep
                                                                                                                }{" "}
                                                                                                                -{" "}
                                                                                                                {
                                                                                                                    departement.dep_name
                                                                                                                }
                                                                                                            </small>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </>
                                                                                        )
                                                                                    )}
                                                                            </>
                                                                        )}
                                                                </>
                                                            )
                                                        )}
                                                </div>

                                                {elements !== null &&
                                                    elements !== undefined &&
                                                    elements.length > 0 &&
                                                    elements.filter(
                                                        (element) =>
                                                            (element.country ===
                                                                "Suisse" ||
                                                                element.country ===
                                                                    "suisse") &&
                                                            element.visible ===
                                                                true &&
                                                            (element.category.includes(
                                                                filterCat.id
                                                            ) === true ||
                                                                filterCat.name ===
                                                                    "")
                                                    ).length >= 1 && (
                                                        <h4 className="small-country">
                                                            {filterCat.name} En
                                                            Suisse :{" "}
                                                            {activDevelop ===
                                                                true && (
                                                                <span
                                                                    className={
                                                                        developSui ===
                                                                        true
                                                                            ? "developIcon"
                                                                            : "developIcon-reverse"
                                                                    }
                                                                    onClick={() =>
                                                                        setDevelopSui(
                                                                            !developSui
                                                                        )
                                                                    }
                                                                >
                                                                    {developSui ===
                                                                    true
                                                                        ? "Fermer"
                                                                        : "Développer"}{" "}
                                                                    <MdExpandMore />
                                                                </span>
                                                            )}
                                                        </h4>
                                                    )}
                                                <div
                                                    className={
                                                        developSui === false
                                                            ? "modal-result-search-hidden"
                                                            : "modal-result-search"
                                                    }
                                                >
                                                    {elements !== null &&
                                                        elements !==
                                                            undefined &&
                                                        elements.length > 0 &&
                                                        elements.map(
                                                            (element) => (
                                                                <>
                                                                    {(element.country ===
                                                                        "Suisse" ||
                                                                        element.country ===
                                                                            "suisse") &&
                                                                        element.visible ===
                                                                            true &&
                                                                        (element.category.includes(
                                                                            filterCat.id
                                                                        ) ===
                                                                            true ||
                                                                            filterCat.name ===
                                                                                "") && (
                                                                            <div
                                                                                className="pointer"
                                                                                onClick={() => {
                                                                                    map.setView(
                                                                                        [
                                                                                            parseFloat(
                                                                                                element.gps.split(
                                                                                                    ","
                                                                                                )[0]
                                                                                            ),
                                                                                            parseFloat(
                                                                                                element.gps.split(
                                                                                                    ","
                                                                                                )[1]
                                                                                            ),
                                                                                        ],
                                                                                        13
                                                                                    );
                                                                                    handleClick(
                                                                                        element
                                                                                    );
                                                                                    dispatch(
                                                                                        openModal()
                                                                                    );
                                                                                }}
                                                                                onMouseOver={() =>
                                                                                    setShowOnMap(
                                                                                        element
                                                                                    )
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    setShowOnMap(
                                                                                        undefined
                                                                                    )
                                                                                }
                                                                            >
                                                                                <h3>
                                                                                    {
                                                                                        element.title
                                                                                    }
                                                                                </h3>

                                                                                {element.url_img_header !==
                                                                                    "none" && (
                                                                                    <img
                                                                                        className="img-map-gestion mx-auto"
                                                                                        src={
                                                                                            element.url_img_header
                                                                                        }
                                                                                        alt={
                                                                                            element.title
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                <small>
                                                                                    {" "}
                                                                                    {
                                                                                        element.zipcode
                                                                                    }{" "}
                                                                                    -
                                                                                    {
                                                                                        element.city
                                                                                    }
                                                                                </small>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )
                                                        )}{" "}
                                                </div>
                                                {elements !== null &&
                                                    elements !== undefined &&
                                                    elements.length > 0 &&
                                                    elements.filter(
                                                        (element) =>
                                                            (element.country ===
                                                                "Monaco" ||
                                                                element.country ===
                                                                    "monaco") &&
                                                            element.visible ===
                                                                true &&
                                                            (element.category.includes(
                                                                filterCat.id
                                                            ) === true ||
                                                                filterCat.name ===
                                                                    "")
                                                    ).length >= 1 && (
                                                        <h4 className="small-country">
                                                            {filterCat.name} A
                                                            Monaco :{" "}
                                                            {activDevelop ===
                                                                true && (
                                                                <span
                                                                    className={
                                                                        developMonaco ===
                                                                        true
                                                                            ? "developIcon"
                                                                            : "developIcon-reverse"
                                                                    }
                                                                    onClick={() =>
                                                                        setDevelopMonaco(
                                                                            !developMonaco
                                                                        )
                                                                    }
                                                                >
                                                                    {developMonaco ===
                                                                    true
                                                                        ? "Fermer"
                                                                        : "Développer"}{" "}
                                                                    <MdExpandMore />
                                                                </span>
                                                            )}
                                                        </h4>
                                                    )}
                                                <div
                                                    className={
                                                        developMonaco === false
                                                            ? "modal-result-search-hidden"
                                                            : "modal-result-search"
                                                    }
                                                >
                                                    {elements !== null &&
                                                        elements !==
                                                            undefined &&
                                                        elements.length > 0 &&
                                                        elements.map(
                                                            (element) => (
                                                                <>
                                                                    {(element.country ===
                                                                        "Monaco" ||
                                                                        element.country ===
                                                                            "Monaco") &&
                                                                        element.visible ===
                                                                            true &&
                                                                        (element.category.includes(
                                                                            filterCat.id
                                                                        ) ===
                                                                            true ||
                                                                            filterCat.name ===
                                                                                "") && (
                                                                            <div
                                                                                className="pointer"
                                                                                onClick={() => {
                                                                                    map.setView(
                                                                                        [
                                                                                            parseFloat(
                                                                                                element.gps.split(
                                                                                                    ","
                                                                                                )[0]
                                                                                            ),
                                                                                            parseFloat(
                                                                                                element.gps.split(
                                                                                                    ","
                                                                                                )[1]
                                                                                            ),
                                                                                        ],
                                                                                        13
                                                                                    );
                                                                                    handleClick(
                                                                                        element
                                                                                    );
                                                                                    dispatch(
                                                                                        openModal()
                                                                                    );
                                                                                }}
                                                                                onMouseOver={() =>
                                                                                    setShowOnMap(
                                                                                        element
                                                                                    )
                                                                                }
                                                                                onMouseLeave={() =>
                                                                                    setShowOnMap(
                                                                                        undefined
                                                                                    )
                                                                                }
                                                                            >
                                                                                <h3>
                                                                                    {
                                                                                        element.title
                                                                                    }
                                                                                </h3>

                                                                                {element.url_img_header !==
                                                                                    "none" && (
                                                                                    <img
                                                                                        className="img-map-gestion mx-auto"
                                                                                        src={
                                                                                            element.url_img_header
                                                                                        }
                                                                                        alt={
                                                                                            element.title
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                <small>
                                                                                    {" "}
                                                                                    {
                                                                                        element.zipcode
                                                                                    }{" "}
                                                                                    -
                                                                                    {
                                                                                        element.city
                                                                                    }
                                                                                </small>
                                                                            </div>
                                                                        )}
                                                                </>
                                                            )
                                                        )}{" "}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <Modal />
                                </div>
                            </div>
                        </main>
                        <SingeFooterShopifyVelomad />
                        <FooterShopifyVelomad />
                    </>
                )}
            </>
        );
    }

    return <ExternalStateExample />;
}
